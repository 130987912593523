.dropdown-style{
    color: #c19556;
    font-size: 25px;
    
}

.menu-name{
    color: #c19556;
    font-size: 16px;
    font-family: open sans;
    font-weight: 600;
    
}

.menu{
    border: 0.01px solid #c19556;
    background-color: rgb(22, 22, 22);
    
}


.menu-item, .menu-price{
    color: #efefef;
    font-size: 15px;
    font-family: Lato, sans-serif;
    font-weight: 600;
}

.hr-line{
    margin-top: -10px;
    color: #c19556;
}

.menu-img1{
    width: 100%;
    height: 100%;
   
}

.menu-img2{
    width: 100%;
    height: 100%;
   
}

.outer1{
    width: 282px;
    height: 180px;
    margin-left: 45px;
    margin-top: 11px;
    overflow: hidden;
    
}

.outer2{
    width: 282px;
    height: 180px;
    margin-left: 60px;
    margin-top: 11px;
    overflow: hidden;

}

.outer1:hover .menu-img1{
    transform: scale(1.3);
    transition: all 0.2s;
    opacity: 0.5;
}

.outer2:hover .menu-img2{
    transform: scale(1.3);
    transition: all 0.2s;
    opacity: 0.5;
}


@media screen and (max-width: 768px) {
    .menu-img1{
        width: 100%;
        height: 100%;
       
    }
    
    .menu-img2{
        width: 100%;
        height: 100%;
        
    }

    .outer1{
        width: 100%;
        height: 180px;
        margin-left: 0px;
       margin-top: 11px;
        overflow: hidden;
    }


    .outer2{
        width: 100%;
        height: 180px;
        margin-left: 0px;
        margin-top: 11px;
        overflow: hidden;
    }
    
}


.after-menu{
   
    text-align: center;
    font-size: 32px;
    font-family: open sans;
    font-weight: 300;
}

.table-crowd{
    width: 100%;
    max-height: 600px;
}

.table-text{
    font-family: dancing script;
    position: absolute;
    margin-top: -600px;
    color: white;
    font-size: 80px;
}

@media screen and (max-width: 768px)
{
    .table-text{
        font-size: 60px;
        margin-top: -200px;
        text-align: center;
    }
    
}