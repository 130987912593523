/* use landing.jpg as background image */
.main {
  text-align: center;
  background-image: url('./media/Banner_1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  /* height: 100vh; */
}

.navbar{
  background-color: rgba(0, 0, 0, 0.664);
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
}

.ttl{
  font-family: 'Dancing Script', cursive;
  color: #e7b269;
  font-size: 44px;
  margin-top: 20px;
  margin-left: 30px;
}


@media screen and (max-width: 768px) {
  .navbar-nav{
    background-color: rgba(0, 0, 0, 0.973);
    width: 100%;
    height: 75vh;
    margin-top: 20px;
    left: -100%;
    text-align: center;
    transition: all 0.5s;
    
    
  }

  .ttl{
    font-family: 'Dancing Script', cursive;
    color: #e7b269;
    font-size: 32px;
    margin-top: 5px;
    margin-left: 0px;
  }

 

  .nav-border.hover{
    border-top: none !important;
    border-radius: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    color: #C19556;
  }
  
}



.navbar-toggler{
  background-color: white;
  border: none;
}


.nav-back{
  background-color: rgba(0, 0, 0, 0.589);
  
}


.nav-link-css
{
  color: white !important;
  font-size: 17px;
  font-family: Open Sans;
  font-weight: 600;
}



.nav-border :hover
{
  border-top: 5px solid #c19556 ;
  border-radius: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  color: #C19556 !important;
}
  
  
  


.nav-item :hover
{
  border-top: none !important;
  color: #C19556 !important;
  

}







